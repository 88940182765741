// Import Features
import produce from "immer";
import { API } from "../../api/API";
import { Api } from "../actions";
// Types
import type { Action } from "../../types/common.types";
type State = {
  initAPI: boolean;
  api: {
    ready: boolean;
  };
};
// Api Reducer
const initialState = {
  initAPI: false,
  api: {
    ready: false,
  },
};
const api: (state: State, action: Action) => State = produce(
  (draft: State, action: Action) => {
    switch (action.type) {
      case Api.INIT_API:
        draft.initAPI = true;
        draft.api = new API();
        break;
    }
  },
  initialState
);
export default api;
