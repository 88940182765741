import { createAction } from "deox";
// Actions for Users
// Activate Account
export const USERS_ACTIVATE_ACCOUNT_CHECK_TOKEN: string =
  "USERS/ACTIVATE_ACCOUNT/CHECK_TOKEN";
export const USERS_ACTIVATE_ACCOUNT_CHECK_TOKEN_REQUEST: string =
  "USERS/ACTIVATE_ACCOUNT/CHECK_TOKEN/REQUEST";
export const USERS_ACTIVATE_ACCOUNT_CHECK_TOKEN_SUCCESS: string =
  "USERS/ACTIVATE_ACCOUNT/CHECK_TOKEN/SUCCESS";
export const USERS_ACTIVATE_ACCOUNT_CHECK_TOKEN_FAILURE: string =
  "USERS/ACTIVATE_ACCOUNT/CHECK_TOKEN/FAILURE";
export const usersActivateAccountCheckToken = createAction(
  USERS_ACTIVATE_ACCOUNT_CHECK_TOKEN,
  (resolve) => (payload: Object) => resolve(payload)
);
export const usersActivateAccountCheckTokenRequest = createAction(
  USERS_ACTIVATE_ACCOUNT_CHECK_TOKEN_REQUEST
);
export const usersActivateAccountCheckTokenSuccess = createAction(
  USERS_ACTIVATE_ACCOUNT_CHECK_TOKEN_SUCCESS
);
export const usersActivateAccountCheckTokenFailure = createAction(
  USERS_ACTIVATE_ACCOUNT_CHECK_TOKEN_FAILURE
);
