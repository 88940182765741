import { createAction } from "deox";
// Actions for Invitation
// <Invitation> (Create Company Account)
// Check Company Invitation
export const INVITATION_COMPANY_CHECK_TOKEN: string =
  "INVITATION/COMPANY/CHECK_TOKEN";
export const INVITATION_COMPANY_CHECK_TOKEN_REQUEST: string =
  "INVITATION/COMPANY/CHECK_TOKEN/REQUEST";
export const INVITATION_COMPANY_CHECK_TOKEN_SUCCESS: string =
  "INVITATION/COMPANY/CHECK_TOKEN/SUCCESS";
export const INVITATION_COMPANY_CHECK_TOKEN_FAILURE: string =
  "INVITATION/COMPANY/CHECK_TOKEN/FAILURE";
export const invitationCompanyCheckToken = createAction(
  INVITATION_COMPANY_CHECK_TOKEN,
  (resolve) => (payload: Object) => resolve(payload)
);
export const invitationCompanyCheckTokenRequest = createAction(
  INVITATION_COMPANY_CHECK_TOKEN_REQUEST
);
export const invitationCompanyCheckTokenSuccess = createAction(
  INVITATION_COMPANY_CHECK_TOKEN_SUCCESS,
  (resolve) => (payload: Object) => resolve(payload)
);
export const invitationCompanyCheckTokenFailure = createAction(
  INVITATION_COMPANY_CHECK_TOKEN_FAILURE
);
// Check User Invitation
export const INVITATION_USER_CHECK_TOKEN: string =
  "INVITATION/USER/CHECK_TOKEN";
export const INVITATION_USER_CHECK_TOKEN_REQUEST: string =
  "INVITATION/USER/CHECK_TOKEN/REQUEST";
export const INVITATION_USER_CHECK_TOKEN_SUCCESS: string =
  "INVITATION/USER/CHECK_TOKEN/SUCCESS";
export const INVITATION_USER_CHECK_TOKEN_FAILURE: string =
  "INVITATION/USER/CHECK_TOKEN/FAILURE";
export const invitationUserCheckToken = createAction(
  INVITATION_USER_CHECK_TOKEN,
  (resolve) => (payload: Object) => resolve(payload)
);
export const invitationUserCheckTokenRequest = createAction(
  INVITATION_USER_CHECK_TOKEN_REQUEST
);
export const invitationUserCheckTokenSuccess = createAction(
  INVITATION_USER_CHECK_TOKEN_SUCCESS,
  (resolve) => (payload: Object) => resolve(payload)
);
export const invitationUserCheckTokenFailure = createAction(
  INVITATION_USER_CHECK_TOKEN_FAILURE
);
// Create Tenant (Subdomain)
export const INVITATION_CREATE_TENANT: string = "INVITATION/CREATE_TENANT";
export const INVITATION_CREATE_TENANT_REQUEST: string =
  "INVITATION/CREATE_TENANT/REQUEST";
export const INVITATION_CREATE_TENANT_SUCCESS: string =
  "INVITATION/CREATE_TENANT/SUCCESS";
export const INVITATION_CREATE_TENANT_FAILURE: string =
  "INVITATION/CREATE_TENANT/FAILURE";
export const invitationCreateTenant = createAction(
  INVITATION_CREATE_TENANT,
  (resolve) => (payload: Object) => resolve(payload)
);
export const invitationCreateTenantRequest = createAction(
  INVITATION_CREATE_TENANT_REQUEST
);
export const invitationCreateTenantSuccess = createAction(
  INVITATION_CREATE_TENANT_SUCCESS,
  (resolve) => (payload: Object) => resolve(payload)
);
export const invitationCreateTenantFailure = createAction(
  INVITATION_CREATE_TENANT_FAILURE
);
// Create Company Account
export const INVITATION_CREATE_COMPANY_ACCOUNT: string =
  "INVITATION/CREATE_COMPANY_ACCOUNT";
export const INVITATION_CREATE_COMPANY_ACCOUNT_REQUEST: string =
  "INVITATION/CREATE_COMPANY_ACCOUNT/REQUEST";
export const INVITATION_CREATE_COMPANY_ACCOUNT_SUCCESS: string =
  "INVITATION/CREATE_COMPANY_ACCOUNT/SUCCESS";
export const INVITATION_CREATE_COMPANY_ACCOUNT_FAILURE: string =
  "INVITATION/CREATE_COMPANY_ACCOUNT/FAILURE";
export const invitationCreateCompanyAccount = createAction(
  INVITATION_CREATE_COMPANY_ACCOUNT,
  (resolve) => (payload: Object) => resolve(payload)
);
export const invitationCreateCompanyAccountRequest = createAction(
  INVITATION_CREATE_COMPANY_ACCOUNT_REQUEST
);
export const invitationCreateCompanyAccountSuccess = createAction(
  INVITATION_CREATE_COMPANY_ACCOUNT_SUCCESS
);
export const invitationCreateCompanyAccountFailure = createAction(
  INVITATION_CREATE_COMPANY_ACCOUNT_FAILURE
);
// Create User Account
export const INVITATION_CREATE_USER_ACCOUNT: string =
  "INVITATION/CREATE_USER_ACCOUNT";
export const INVITATION_CREATE_USER_ACCOUNT_REQUEST: string =
  "INVITATION/CREATE_USER_ACCOUNT/REQUEST";
export const INVITATION_CREATE_USER_ACCOUNT_SUCCESS: string =
  "INVITATION/CREATE_USER_ACCOUNT/SUCCESS";
export const INVITATION_CREATE_USER_ACCOUNT_FAILURE: string =
  "INVITATION/CREATE_USER_ACCOUNT/FAILURE";
export const invitationCreateUserAccount = createAction(
  INVITATION_CREATE_USER_ACCOUNT,
  (resolve) => (payload: Object) => resolve(payload)
);
export const invitationCreateUserAccountRequest = createAction(
  INVITATION_CREATE_USER_ACCOUNT_REQUEST
);
export const invitationCreateUserAccountSuccess = createAction(
  INVITATION_CREATE_USER_ACCOUNT_SUCCESS
);
export const invitationCreateUserAccountFailure = createAction(
  INVITATION_CREATE_USER_ACCOUNT_FAILURE
);
// Create Company - User Account Done
export const INVITATION_PROCESS_DONE: string = "INVITATION/PROCESS_DONE";
export const invitationProcessDone = createAction(INVITATION_PROCESS_DONE);
// </Invitation>
