// Inclusive sagas
import { all, fork } from "redux-saga/effects";
import { logger } from "./logger";
import appSagas from "./app";
import authSagas from "./auth";
import contactSagas from "./contact";
import invitationSagas from "./invitation";
import usersSagas from "./users";

function* readySaga() {
  // init logger:
  yield logger();
  yield window.logger.log("Sagas Inclusive Ready!");
}

export function* sagas(): Generator<any, any, any> {
  yield all([
    fork(readySaga),
    fork(appSagas),
    fork(authSagas),
    fork(invitationSagas),
    fork(usersSagas),
    fork(contactSagas),
  ]);
}
