// Inclusive Redux Store
/* eslint-disable no-useless-escape */
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import { App } from "./actions/";
import { reducers } from "./reducers/";
import { sagas } from "./sagas/";
import { isDevelopmentReactAppEnv } from "../utils";
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    logger: {
      log: (...args: any[]) => void;
      info: (...args: any[]) => void;
      warn: (...args: any[]) => void;
      error: (...args: any[]) => void;
    };
  }
}
// Middleware:
const configureStore = () => {
  // Add the saga middleware
  const sagaMiddleware: any = createSagaMiddleware();
  // Build the middleware for intercepting and dispatching navigation actions
  let middlewares: any[] = [sagaMiddleware];
  let composeEnhancers: Function = compose;
  // Add freeze and redux dev tools, only production
  if (isDevelopmentReactAppEnv()) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  let preloadedState: any = {};
  const store = createStore(reducers, preloadedState, enhancer);
  sagaMiddleware.run(sagas);
  store.dispatch(App.initAPP());
  return store;
};
// export
export { configureStore };
