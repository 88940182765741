// @flow
import { put, select, call, all, takeLatest } from "redux-saga/effects";
import type { Action } from "deox";
import ContactApi from "../../api/Contact";
import { Contact } from "../actions";
//Sagas for Contact Send Form
function* doContactSendForm(
  action: Action<
    string,
    {
      body: Object;
      callbackSuccess?: () => void;
      callbackError?: (message: string) => void;
    }
  >
) {
  window.logger.log("doContactSendForm", action);
  yield put(Contact.contactSendFormRequest());
  const {
    body,
    callbackSuccess = null,
    callbackError = undefined,
  } = action.payload;
  try {
    const api = yield select(({ api }) => api.api);
    // call the api to send a contact form
    yield call(ContactApi.SendForm, api, body);
    yield put(Contact.contactSendFormSuccess());
    if (callbackSuccess) {
      callbackSuccess();
    }
  } catch (error) {
    window.logger.error("ErrorSaga - doContactSendForm", error);
    yield put(Contact.contactSendFormFailure());
    if (callbackError) {
      callbackError(error);
    }
  }
}
export default function* contactSagas(): Generator<any, any, any> {
  yield all([takeLatest(Contact.CONTACT_SEND_FORM, doContactSendForm)]);
}
