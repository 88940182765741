import type { tAPI } from "./API";
// API Invitation static class
export default class Invitation {
  // Check Invitation
  // Company
  static CheckCompanyInvitation(api: tAPI, data: Object) {
    return api.POST("company-invitations/check-token", data);
  }
  // User
  static CheckUserInvitation(
    api: tAPI,
    data: Object,
    tenant: string,
    token: string
  ) {
    return api.POST("user-invitations/check-token", data, { tenant, token });
  }
  // Create Tenant
  static CreateTenant(api: tAPI, data: Object, token: string) {
    return api.POST("tenants", data, { token });
  }
  // Create Company
  static CreateCompany(api: tAPI, data: Object, tenant: string, token: string) {
    return api.POST("companies", data, { tenant, token });
  }
  // Create Account
  static CreateAccount(api: tAPI, data: Object, tenant: string, token: string) {
    return api.POST("users", data, { tenant, token });
  }
}
