// @flow
import { put, select, call, all, takeLatest } from "redux-saga/effects";
import type { Action } from "deox";
import UsersApi from "../../api/Users";
import { Users } from "../actions";
import { LocalStore } from "../../utils/local-storage/localStore";
import { ServiceErrors } from "../../utils/service-errors/serviceErrors";
//Sagas for Users
function* doUsersActivateAccount(
  action: Action<
    string,
    {
      tenant: string;
      confirmationToken: string;
      cbs?: () => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doUsersActivateAccount", action);
  yield put(Users.usersActivateAccountCheckTokenRequest());
  const { tenant, confirmationToken, cbs = null, cbe = null } = action.payload;
  try {
    const api = yield select(({ api }) => api.api);
    const token = LocalStore.Read("invitationApiToken") as string;
    // call the api to check the invitation
    yield call(
      UsersApi.ActivateAccount,
      api,
      {
        confirmationToken,
      },
      tenant,
      token
    );
    yield put(Users.usersActivateAccountCheckTokenSuccess());
    LocalStore.Delete("invitationApiToken");
    if (cbs) {
      cbs();
    }
  } catch (error) {
    window.logger.error("ErrorSaga - doUsersActivateAccount");
    const errorMsg = ServiceErrors.Error(error);
    yield put(Users.usersActivateAccountCheckTokenFailure());
    if (cbe) {
      cbe(errorMsg);
    }
  }
}

export default function* usersSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      Users.USERS_ACTIVATE_ACCOUNT_CHECK_TOKEN,
      doUsersActivateAccount
    ),
  ]);
}
