// Import Features
import * as React from "react";
// Import Components
import { Redirect, Switch, useLocation } from "react-router-dom";
// ROUTER:
const { useEffect, lazy, Suspense } = React;
// Import Routes Dynamic:
const RouterPublic = lazy(() => import("./RouterPublic"));
const RouterAuth = lazy(() => import("./RouterAuth"));
const publicRoutes = [
  "/",
  "/home",
  "/signin",
  "/login",
  "/forgot-password",
  "/contact",
];
const authRoutes = [
  /\/activate-account\/[a-zA-Z0-9]{3,}\/[a-zA-Z0-9]{24,}/gi,
  /\/change-password\/[a-zA-Z0-9]{3,}\/[a-zA-Z0-9]{24}/gi,
  /\/join\/user\/[a-zA-Z0-9]{3,}\/[a-zA-Z0-9]{24,}/gi,
  /\/join\/company\/[a-zA-Z0-9]{24,}/gi,
];
const RouterApp = () => {
  const { pathname, ...rest } = useLocation();
  useEffect(() => {
    window.logger.log("RouterApp", pathname, rest);
  });
  return (
    <Suspense fallback={null}>
      <Switch>
        {publicRoutes.includes(pathname) && <RouterPublic />}
        {authRoutes.filter((regex) => pathname.match(regex) !== null).length >
          0 && <RouterAuth />}
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};
export default RouterApp;
