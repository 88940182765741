import { put, all, takeLatest } from "redux-saga/effects";
import type { AnyAction } from "deox";
import { Api, App } from "../actions";
//Sagas for App
function* doInitApp(action: AnyAction) {
  yield window.logger.log("doInitApp", action);
  yield put(Api.initAPI());
}
export default function* appSagas(): Generator<any, any, any> {
  yield all([takeLatest(App.INIT_APP, doInitApp)]);
}
