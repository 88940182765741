import type { tAPI } from "./API";
// API Users static class
export default class Users {
  // Activate Account
  static ActivateAccount(
    api: tAPI,
    data: Object,
    tenant: string,
    token: string
  ) {
    return api.POST("users/check-token", data, { tenant, token });
  }
}
