import type { tAPI } from "./API";
// API Users static class
export default class Auth {
  // Recover Password
  static RecoverPassword(api: tAPI, data: Object, tenant: string) {
    return api.POST("recover-password", data, { tenant });
  }
  // Reset Password
  static ResetPassword(api: tAPI, data: Object, tenant: string) {
    return api.POST("reset-password", data, { tenant });
  }
}
