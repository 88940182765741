import { createAction } from "deox";
// Actions for Auth
// Recover Password
export const AUTH_RECOVER_PASSWORD: string = "AUTH/RECOVER_PASSWORD";
export const AUTH_RECOVER_PASSWORD_REQUEST: string =
  "AUTH/RECOVER_PASSWORD/REQUEST";
export const AUTH_RECOVER_PASSWORD_SUCCESS: string =
  "AUTH/RECOVER_PASSWORD/SUCCESS";
export const AUTH_RECOVER_PASSWORD_FAILURE: string =
  "AUTH/RECOVER_PASSWORD/FAILURE";
export const authRecoverPassword = createAction(
  AUTH_RECOVER_PASSWORD,
  (resolve) => (payload: Object) => resolve(payload)
);
export const authRecoverPasswordRequest = createAction(
  AUTH_RECOVER_PASSWORD_REQUEST
);
export const authRecoverPasswordSuccess = createAction(
  AUTH_RECOVER_PASSWORD_SUCCESS
);
export const authRecoverPasswordFailure = createAction(
  AUTH_RECOVER_PASSWORD_FAILURE
);
// Reset Password
export const AUTH_RESET_PASSWORD: string = "AUTH/RESET_PASSWORD";
export const AUTH_RESET_PASSWORD_REQUEST: string =
  "AUTH/RESET_PASSWORD/REQUEST";
export const AUTH_RESET_PASSWORD_SUCCESS: string =
  "AUTH/RESET_PASSWORD/SUCCESS";
export const AUTH_RESET_PASSWORD_FAILURE: string =
  "AUTH/RESET_PASSWORD/FAILURE";
export const authResetPassword = createAction(
  AUTH_RESET_PASSWORD,
  (resolve) => (payload: Object) => resolve(payload)
);
export const authResetPasswordRequest = createAction(
  AUTH_RESET_PASSWORD_REQUEST
);
export const authResetPasswordSuccess = createAction(
  AUTH_RESET_PASSWORD_SUCCESS
);
export const authResetPasswordFailure = createAction(
  AUTH_RESET_PASSWORD_FAILURE
);
