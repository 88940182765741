import { createAction } from "deox";
// Actions for Contact Form
// Send Contact Form
export const CONTACT_SEND_FORM: string = "CONTACT/SEND/FORM";
export const CONTACT_SEND_FORM_REQUEST: string = "CONTACT/SEND/FORM/REQUEST";
export const CONTACT_SEND_FORM_SUCCESS: string = "CONTACT/SEND/FORM/SUCCESS";
export const CONTACT_SEND_FORM_FAILURE: string = "CONTACT/SEND/FORM/FAILURE";
export const contactSendForm = createAction(
  CONTACT_SEND_FORM,
  (resolve) => (payload: Object) => resolve(payload)
);
export const contactSendFormRequest = createAction(CONTACT_SEND_FORM_REQUEST);
export const contactSendFormSuccess = createAction(CONTACT_SEND_FORM_SUCCESS);
export const contactSendFormFailure = createAction(CONTACT_SEND_FORM_FAILURE);
