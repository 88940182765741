// @flow
import { put, select, call, all, takeLatest } from "redux-saga/effects";
import type { Action } from "deox";
import AuthApi from "../../api/Auth";
import { Auth } from "../actions";
import { ServiceErrors } from "../../utils/service-errors/serviceErrors";
//Sagas for Auth
function* doAuthRecoverPassword(
  action: Action<
    string,
    {
      tenant: string;
      email: string;
      cbs?: () => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doAuthRecoverPassword", action);
  yield put(Auth.authRecoverPasswordRequest());
  const { tenant, email, cbs = null, cbe = null } = action.payload;
  try {
    const api = yield select(({ api }) => api.api);
    // call the api to recover the password
    yield call(
      AuthApi.RecoverPassword,
      api,
      {
        email,
      },
      tenant
    );
    yield put(Auth.authRecoverPasswordSuccess());
    if (cbs) {
      cbs();
    }
  } catch (error) {
    window.logger.error("ErrorSaga - doAuthRecoverPassword");
    const errorMsg = ServiceErrors.Error(error);
    yield put(Auth.authRecoverPasswordFailure());
    if (cbe) {
      cbe(errorMsg);
    }
  }
}
function* doAuthResetPassword(
  action: Action<
    string,
    {
      tenant: string;
      recoveryPasswordToken: string;
      password: string;
      cbs?: () => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doAuthResetPassword", action);
  yield put(Auth.authResetPasswordRequest());
  const {
    tenant,
    recoveryPasswordToken,
    password,
    cbs = null,
    cbe = null,
  } = action.payload;
  try {
    const api = yield select(({ api }) => api.api);
    // call the api to reset the password
    yield call(
      AuthApi.ResetPassword,
      api,
      {
        recoveryPasswordToken,
        password,
      },
      tenant
    );
    yield put(Auth.authResetPasswordSuccess());
    if (cbs) {
      cbs();
    }
  } catch (error) {
    window.logger.error("ErrorSaga - doAuthResetPassword");
    const errorMsg = ServiceErrors.Error(error);
    yield put(Auth.authResetPasswordFailure());
    if (cbe) {
      cbe(errorMsg);
    }
  }
}

export default function* authSagas(): Generator<any, any, any> {
  yield all([takeLatest(Auth.AUTH_RECOVER_PASSWORD, doAuthRecoverPassword)]);
  yield all([takeLatest(Auth.AUTH_RESET_PASSWORD, doAuthResetPassword)]);
}
