// Import Features
import * as React from "react";
// Import Components
import { BrowserRouter as Router } from "react-router-dom";
import RouterApp from "../router";
// Import Styles
import "./AppMain.scss";
// APP MAIN:
const AppMain = () => (
  <Router basename="/">
    <RouterApp />
  </Router>
);
export default AppMain;
