// Local Storage to save local information into the browser.
export class LocalStore {
  static Save(key: string, value: string | number | Object): string {
    localStorage.setItem(key, JSON.stringify(value));
    return JSON.stringify(value);
  }

  static Delete(key: string): void {
    localStorage.removeItem(key);
  }

  static Read(key: string): string | number | Object {
    const value = localStorage.getItem(key) || "null";
    return JSON.parse(value);
  }

  static ClearAll() {
    localStorage.clear();
  }

  static numKeys(): number {
    return localStorage.length;
  }
}
