// Import Features
import produce from "immer";
import { App } from "../actions";
import { app as appJson } from "../../app.json";
import { isMobileBrowser, isTouchableDevice } from "../../utils/";
// Types
import type { Action } from "../../types/common.types";
type State = {
  initAPP: boolean;
  name: string;
  slug: string;
  description: string;
  version: string;
  appURL: string;
  appBuildDate: string;
  appEnv: string;
  apiEnv: string;
  appWeb: {
    origin: string;
    protocol: string;
    hostname: string;
    port: string;
    suffix: string;
  };
  isMobileBrowser: boolean;
  isTouchableDevice: boolean;
};
// App Reducer
const initialState = {
  initAPP: false,
  ...appJson,
  appWeb: {
    origin: window.location.origin
      ? window.location.origin
      : `${window.location.protocol}//${window.location.host}`,
    protocol: `${window.location.protocol}//`,
    hostname: window.location.hostname,
    port: window.location.port.length > 0 ? `:${window.location.port}` : "",
    suffix: ".io",
  },
  isMobileBrowser: isMobileBrowser(),
  isTouchableDevice: isTouchableDevice(),
};
const app: (state: State, action: Action) => State = produce(
  (draft: State, action: Action) => {
    switch (action.type) {
      case App.INIT_APP:
        draft.initAPP = true;
    }
  },
  initialState
);

export default app;
