// Inclusive reducers
import { combineReducers } from "redux";
import api from "./api";
import app from "./app";
import invitation from "./invitation";

// main reducers
export const reducers: any = combineReducers({
  api,
  app,
  invitation,
});
export type RootState = ReturnType<typeof reducers>;
