// Import Features
import produce from "immer";
import { Invitation } from "../actions";
// Types
import type { Action } from "../../types/common.types";
type State = {
  step:
    | "check-invitation"
    | "create-tenant"
    | "create-account"
    | "process-done";
  apiToken: string;
  tenant: string;
  company: string;
};
// Stripe Reducer
const initialState: State = {
  step: "check-invitation",
  apiToken: "",
  tenant: "",
  company: "",
};
const invitation: (state: State, action: Action) => State = produce(
  (draft: State, action: Action) => {
    switch (action.type) {
      case Invitation.INVITATION_COMPANY_CHECK_TOKEN_SUCCESS:
        draft.apiToken = action.payload.apiToken;
        draft.step = "create-tenant";
        if (action.payload.tenant) {
          draft.tenant = action.payload.tenant;
          draft.step = "create-account";
        }
        if (action.payload.company) {
          draft.company = action.payload.company;
        }
        break;
      case Invitation.INVITATION_USER_CHECK_TOKEN_SUCCESS:
        draft.apiToken = action.payload.apiToken;
        draft.tenant = action.payload.tenant;
        draft.step = "create-account";
        break;
      case Invitation.INVITATION_CREATE_TENANT_SUCCESS:
        draft.tenant = action.payload.tenant;
        draft.step = "create-account";
        break;
      case Invitation.INVITATION_CREATE_COMPANY_ACCOUNT_SUCCESS:
        draft.step = "process-done";
        break;
      case Invitation.INVITATION_PROCESS_DONE:
        return initialState;
    }
  },
  initialState
);

export default invitation;
