// @flow
import { put, select, call, all, takeLatest } from "redux-saga/effects";
import type { Action } from "deox";
import InvitationApi from "../../api/Invitation";
import { Invitation } from "../actions";
import { LocalStore } from "../../utils/local-storage/localStore";
import { ServiceErrors } from "../../utils/service-errors/serviceErrors";
//Sagas for Invitation
function* doInvitationCompanyCheckToken(
  action: Action<
    string,
    {
      invitationToken: string;
      cbs?: () => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doInvitationCompanyCheckToken", action);
  yield put(Invitation.invitationCompanyCheckTokenRequest());
  const { invitationToken, cbs = null, cbe = null } = action.payload;
  try {
    const api = yield select(({ api }) => api.api);
    // call the api to check the invitation
    const {
      data: { company, tenant, token: apiToken },
    } = yield call(InvitationApi.CheckCompanyInvitation, api, {
      invitationToken,
    });
    yield put(
      Invitation.invitationCompanyCheckTokenSuccess({
        apiToken,
        tenant: tenant ? tenant.name : "",
        company: company ? company.name : "",
      })
    );
    if (cbs) {
      cbs();
    }
  } catch (error) {
    window.logger.error("ErrorSaga - doInvitationCompanyCheckToken");
    const errorMsg = ServiceErrors.Error(error);
    yield put(Invitation.invitationCompanyCheckTokenFailure());
    if (cbe) {
      cbe(errorMsg);
    }
  }
}

function* doInvitationUserCheckToken(
  action: Action<
    string,
    {
      tenant: string;
      invitationToken: string;
      cbs?: () => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doInvitationUserCheckToken", action);
  yield put(Invitation.invitationUserCheckTokenRequest());
  const { tenant, invitationToken, cbs = null, cbe = null } = action.payload;
  try {
    const api = yield select(({ api }) => api.api);
    // call the api to check the invitation
    const {
      data: { token: apiToken },
    } = yield call(
      InvitationApi.CheckUserInvitation,
      api,
      {
        invitationToken,
      },
      tenant,
      ""
    );
    yield put(
      Invitation.invitationUserCheckTokenSuccess({
        apiToken,
        tenant,
      })
    );
    if (cbs) {
      cbs();
    }
  } catch (error) {
    window.logger.error("ErrorSaga - doInvitationUserCheckToken");
    const errorMsg = ServiceErrors.Error(error);
    yield put(Invitation.invitationUserCheckTokenFailure());
    if (cbe) {
      cbe(errorMsg);
    }
  }
}

function* doInvitationCreateTenant(
  action: Action<
    string,
    {
      subdomain: string;
      cbs?: () => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doInvitationCreateTenant", action);
  yield put(Invitation.invitationCreateTenantRequest());
  const { subdomain, cbs = null, cbe = null } = action.payload;
  try {
    const api = yield select(({ api }) => api.api);
    const { apiToken: token } = yield select(({ invitation }) => invitation);
    // call the api to create tenant (subdomain)
    const {
      data: { tenant },
    } = yield call(
      InvitationApi.CreateTenant,
      api,
      {
        name: subdomain,
      },
      token
    );
    yield put(
      Invitation.invitationCreateTenantSuccess({
        tenant: tenant ? tenant.name : "",
      })
    );
    if (cbs) {
      cbs();
    }
  } catch (error) {
    window.logger.error("ErrorSaga - doInvitationCreateTenant");
    const errorMsg = ServiceErrors.Error(error);
    yield put(Invitation.invitationCreateTenantFailure());
    if (cbe) {
      cbe(errorMsg);
    }
  }
}

function* doInvitationCreateCompanyAccount(
  action: Action<
    string,
    {
      companyName: string;
      userAdmin: Object;
      cbs?: () => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doInvitationCreateCompanyAccount", action);
  yield put(Invitation.invitationCreateCompanyAccountRequest());
  const { companyName, userAdmin, cbs = null, cbe = null } = action.payload;
  try {
    const api = yield select(({ api }) => api.api);
    const { apiToken: token, tenant } = yield select(
      ({ invitation }) => invitation
    );
    // call the api to create company account
    yield call(
      InvitationApi.CreateCompany,
      api,
      {
        name: companyName,
      },
      tenant,
      token
    );
    yield call(InvitationApi.CreateAccount, api, userAdmin, tenant, token);
    yield put(Invitation.invitationCreateCompanyAccountSuccess());
    LocalStore.Delete("invitationApiToken");
    LocalStore.Save("invitationApiToken", token);
    if (cbs) {
      cbs();
    }
  } catch (error) {
    window.logger.error("ErrorSaga - doInvitationCreateCompanyAccount");
    const errorMsg = ServiceErrors.Error(error);
    yield put(Invitation.invitationCreateCompanyAccountFailure());
    if (cbe) {
      cbe(errorMsg);
    }
  }
}

function* doInvitationCreateUserAccount(
  action: Action<
    string,
    {
      userAccount: Object;
      cbs?: () => void;
      cbe?: (error: any) => void;
    }
  >
) {
  window.logger.log("doInvitationCreateUserAccount", action);
  yield put(Invitation.invitationCreateUserAccountRequest());
  const { userAccount, cbs = null, cbe = null } = action.payload;
  try {
    const api = yield select(({ api }) => api.api);
    const { apiToken: token, tenant } = yield select(
      ({ invitation }) => invitation
    );
    // call the api to create user account
    yield call(InvitationApi.CreateAccount, api, userAccount, tenant, token);
    yield put(Invitation.invitationCreateUserAccountSuccess());
    LocalStore.Delete("invitationApiToken");
    LocalStore.Save("invitationApiToken", token);
    if (cbs) {
      cbs();
    }
  } catch (error) {
    window.logger.error("ErrorSaga - doInvitationCreateUserAccount");
    const errorMsg = ServiceErrors.Error(error);
    yield put(Invitation.invitationCreateUserAccountFailure());
    if (cbe) {
      cbe(errorMsg);
    }
  }
}

export default function* invitationSagas(): Generator<any, any, any> {
  yield all([
    takeLatest(
      Invitation.INVITATION_COMPANY_CHECK_TOKEN,
      doInvitationCompanyCheckToken
    ),
    takeLatest(
      Invitation.INVITATION_USER_CHECK_TOKEN,
      doInvitationUserCheckToken
    ),
    takeLatest(Invitation.INVITATION_CREATE_TENANT, doInvitationCreateTenant),
    takeLatest(
      Invitation.INVITATION_CREATE_COMPANY_ACCOUNT,
      doInvitationCreateCompanyAccount
    ),
    takeLatest(
      Invitation.INVITATION_CREATE_USER_ACCOUNT,
      doInvitationCreateUserAccount
    ),
  ]);
}
