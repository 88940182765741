//Class to manage all api calls
import axios from "axios";
import { app } from "../app.json";
import type { AxiosResponse } from "axios";
// Types
type Config = {
  https?: "https" | "http";
  tenant?: "" | string;
  envApi?: "" | "dev" | "test" | string;
  apiBase?: "getinclusive.io" | string;
  vApi?: "v1" | "v2";
  token?: string;
};
export interface tAPI {
  GET: (url: string, config?: Config) => Promise<any>;
  POST: (url: string, data: any, config?: Config) => Promise<any>;
  PUT: (url: string, data: any, config?: Config) => Promise<any>;
  PATCH: (url: string, data: any, config?: Config) => Promise<any>;
  DELETE: (url: string, config?: Config) => Promise<any>;
}
export class API {
  // Private properties:
  ready: boolean;
  constructor() {
    this.ready = true;
  }
  static __configDefault__: Config = {
    https: "https",
    tenant: "",
    envApi: app.apiEnv,
    apiBase: app.appURL,
    vApi: "v1",
    token: "",
  };
  // Private Methods:
  _setFullURL = (url: string, config: Config): string => {
    const { https, tenant, envApi, apiBase, vApi } = config;
    let fullURL = `${https}://`;
    if (tenant) {
      fullURL += `${tenant}.`;
    }
    if (envApi) {
      fullURL += `${envApi}.`;
    }
    fullURL += `${apiBase}/api/${vApi}/${url}`;
    return fullURL;
  };
  _setHeaders = (token: string, isPostPutPatch: boolean = false): Object => {
    let headers: Object = {
      Accept: "application/json",
    };
    if (token && token.length) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token}`,
      };
    }
    if (isPostPutPatch) {
      headers = {
        ...headers,
        "Content-type": "application/json",
      };
    }
    return headers;
  };
  _setConfigPostPutPatch = (
    method: "POST" | "PUT" | "PATCH",
    data: any,
    token: string
  ): Object => {
    let config = {
      method: method,
      timeout: 100000,
      headers: this._setHeaders(token, true),
      data: JSON.stringify(data),
      validateStatus: function validateStatus(status: number) {
        return status >= 200 && status < 500;
      },
    };
    return config;
  };
  _setErrorAPI = (error: string, message: string = "Server error 500") => {
    return {
      name: error,
      message,
    };
  };
  _validResponse = (
    response: AxiosResponse<any>
  ): { data?: any; ok: boolean } => {
    if (response.headers["content-type"].indexOf("application/json") > -1) {
      return response.data;
    }
    return {
      ok: false,
      ...this._setErrorAPI(
        "Internal Error",
        "content-type is not application/json"
      ),
    };
  };
  _parseResponse = (response: { data?: any; ok: boolean }): Promise<any> => {
    return new Promise((resolve, reject) => {
      if (response.ok) {
        resolve({ success: true, data: response });
      } else {
        reject({ success: false, data: response });
      }
    });
  };
  // Public Methods:
  GET(url: string, config?: Config): Promise<any> {
    const apiConfig = { ...API.__configDefault__, ...config };
    return axios
      .get(this._setFullURL(url, apiConfig), {
        timeout: 100000,
        headers: this._setHeaders(apiConfig.token || ""),
        validateStatus: function validateStatus(status: number) {
          return status >= 200 && status < 500;
        },
      })
      .then(this._validResponse)
      .catch((error) => {
        window.logger.error("ERROR 500 from server", error);
        return {
          ok: false,
          ...this._setErrorAPI(
            "Internal Error",
            error && error.message ? error.message : error
          ),
        };
      })
      .then(this._parseResponse);
  }
  POST(url: string, data: any, config?: Config): Promise<any> {
    const apiConfig = { ...API.__configDefault__, ...config };
    return axios(
      this._setFullURL(url, apiConfig),
      this._setConfigPostPutPatch("POST", data, apiConfig.token || "")
    )
      .then(this._validResponse)
      .catch((error) => {
        window.logger.error("ERROR 500 from server", error);
        return {
          ok: false,
          ...this._setErrorAPI(
            "Internal Error",
            error && error.message ? error.message : error
          ),
        };
      })
      .then(this._parseResponse);
  }
  PUT(url: string, data: any, config?: Config): Promise<any> {
    const apiConfig = { ...API.__configDefault__, ...config };
    return axios(
      this._setFullURL(url, apiConfig),
      this._setConfigPostPutPatch("PUT", data, apiConfig.token || "")
    )
      .then(this._validResponse)
      .catch((error) => {
        window.logger.error("ERROR 500 from server", error);
        return {
          ok: false,
          ...this._setErrorAPI(
            "Internal Error",
            error && error.message ? error.message : error
          ),
        };
      })
      .then(this._parseResponse);
  }
  PATCH(url: string, data: any, config?: Config): Promise<any> {
    const apiConfig = { ...API.__configDefault__, ...config };
    return axios(
      this._setFullURL(url, apiConfig),
      this._setConfigPostPutPatch("PATCH", data, apiConfig.token || "")
    )
      .then(this._validResponse)
      .catch((error) => {
        window.logger.error("ERROR 500 from server", error);
        return {
          ok: false,
          ...this._setErrorAPI(
            "Internal Error",
            error && error.message ? error.message : error
          ),
        };
      })
      .then(this._parseResponse);
  }
  DELETE(url: string, config?: Config): Promise<any> {
    const apiConfig = { ...API.__configDefault__, ...config };
    return axios(this._setFullURL(url, apiConfig), {
      method: "DELETE",
      headers: this._setHeaders(apiConfig.token || ""),
      validateStatus: function validateStatus(status) {
        return status >= 200 && status < 500;
      },
    })
      .then(this._validResponse)
      .catch((error) => {
        window.logger.error("ERROR 500 from server", error);
        return {
          ok: false,
          ...this._setErrorAPI(
            "Internal Error",
            error && error.message ? error.message : error
          ),
        };
      })
      .then(this._parseResponse);
  }
}
