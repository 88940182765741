// Errors Manager:
// ERRORS:
// JwtkFetchingError: TypeError: Cannot read property 'schema' of undefined
// JwtkExpiredError: invalid token
// JwtkError: jwt expired
// JwtkError: Cannot read property 'id' of null
// TenantAlreadyExistsError: There is a tenant with the same name
// TenantNotAllowedError: You do not have permissions to use this tenant
// TenantNotFoundError: Tenant does not exist
// ApiNotFoundError: Api not found.
// CompanyInvitationNotFoundError: Invitation token does not exist
// HTTPMethodNotAllowed: Operation not supported on /topics
export class ServiceErrors {
  static SetErrorMsg = (errors: { [key: string]: any }) => {
    let name = "",
      message = "";
    if (errors && errors.error) {
      ({ name, message } = errors.error);
    } else {
      ({ name, message } = errors || "");
    }
    switch (name) {
      case "Internal Error":
        return "Something went wrong. Try later.";
      default:
        return message;
    }
  };
  static Error(error: any): string {
    let errorMsg = "";
    if (error) {
      window.logger.error(error);
      const { data: errors } = error;
      errorMsg = ServiceErrors.SetErrorMsg(errors);
    }
    return errorMsg;
  }
}
